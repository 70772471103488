.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.conveyor-grid {
  stroke: black;
  stroke-width: 0.25;
  fill: none;
}

.conveyor-belt {
  stroke: green;
  stroke-width: 0.5em;
}

.conveyor-belt.broken {
  stroke: red;
  stroke-width: 0.5em;
}

.conveyor-junction {
  stroke: lightblue;
  stroke-width: 0.5em;
}

.conveyor-junction.locked {
  stroke: green;
  stroke-width: 0.5em;
}
